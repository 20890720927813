<template>
  <div>
    <v-card>
      <v-card-title> Relatorios </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getOrdemSelect"
              outlined
              label="Ordem(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.orders"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getConstructionsSelect"
              outlined
              label="Obra(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.constructions"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getProvidersSelect"
              outlined
              label="Fornecedor(es)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.providers"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getUserSelect"
              outlined
              label="Usuário(s) que enviou"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.createUsers"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getUserSelect"
              outlined
              label="Motorista(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.loadUsers"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="getUserSelect"
              outlined
              label="Encarregado(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.finishUsers"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getOrderItemsSelect"
              outlined
              label="Material(s)"
              chips
              multiple
              small-chips
              deletable-chips
              counter
              clearable
              v-model="report.items"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field
              label="Placa"
              outlined
              clearable
              v-model="report.plate"
              counter="8"
              maxlength="8"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- filtros de data -->
        <v-row>
          <v-col>
            <v-card tile>
              <v-card-title> Direcionado em 
                <v-spacer></v-spacer>
                <v-btn text @click="getTodayCreated" color="success">
                  Hoje
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Início"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.createS"
                ></v-text-field>
                <v-text-field
                  label="Final"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.createE"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card tile>
              <v-card-title> Carregado em 

                <v-spacer></v-spacer>
                <v-btn text @click="getTodayLoad" color="success">
                  Hoje
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Início"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.loadS"
                ></v-text-field>
                <v-text-field
                  label="Final"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.loadE"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card tile>
              <v-card-title>
                Finalizado em
                <v-spacer></v-spacer>
                <v-btn text @click="getTodayFinish" color="success">
                  Hoje
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Início"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.finishS"
                ></v-text-field>
                <v-text-field
                  label="Final"
                  outlined
                  type="datetime-local"
                  clearable
                  v-model="report.finishE"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- botões -->
      <v-card-actions>
        <!-- excell -->
        <v-btn
          color="success"
          v-show="getBOReport.length"
          @click="exportExcell"
        >
          <v-icon small class="mr-1">mdi-microsoft-excel</v-icon>
          Gerar excell
        </v-btn>
        <!-- pdf -->
        <v-btn color="success" v-show="getBOReport.length" @click="exportPdf">
          <v-icon small class="mr-1">mdi-file-pdf-box</v-icon>
          Gerar PDF
        </v-btn>
        <v-spacer></v-spacer>
        <!-- buscar relatório -->
        <v-btn color="success" @click="loadReport">Buscar Relátorio</v-btn>
      </v-card-actions>
    </v-card>
    <DialogReport />
    <!-- card da tabela -->
    <v-card class="mt-2">
      <v-card-subtitle>Ordens: {{ getBOReport.length }}</v-card-subtitle>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">OC</th>
            <th class="text-left">Fornecedor</th>
            <th class="text-left">Obra</th>
            <th class="text-left">Material</th>
            <th class="text-left">Qtd. Enviada</th>
            <th class="text-left">Quantidade Coletada</th>
            <th class="text-left">Enviado Por</th>
            <th class="text-left">Direcionado em</th>
            <th class="text-left">Descrição</th>
            <th class="text-left">Motorista</th>
            <th class="text-left">Placa</th>
            <th class="text-left">Carregado em</th>
            <th class="text-left">Finalizado Por</th>
            <th class="text-left">Finalizado em</th>
            <th class="text-left">Desc. Finalização</th>
            <th class="text-left">KM</th>
            <th class="text-left">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getBOReport" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.buyOrderId }}</td>
            <td>{{ getProviderNameById(item.order.providerId) }}</td>
            <td>{{ getConstructionNameById(item.order.constructionId) }}</td>
            <td>{{ item.items.map((e) => e.name).join(" - ") }}</td>
            <td>{{ item.amount ? parseFloat(item.amount): "" }}</td>
            <td>{{ item.colectedAmount }}</td>
            <td>{{ getUserNameById(item.createUserId) }}</td>
            <td>
              {{
                item.createdAt ? new Date(item.createdAt).toLocaleString() : ""
              }}
            </td>
            <td>{{ item.description }}</td>
            <td>{{ getUserNameById(item.userId) }}</td>
            <td>{{ item.plate }}</td>
            <td>
              {{
                item.loadedDate
                  ? new Date(item.loadedDate).toLocaleString()
                  : "Não Carregado"
              }}
            </td>
            <td>{{ getUserNameById(item.finishedByUserId) }}</td>
            <td>
              {{
                item.finishedDate
                  ? new Date(item.finishedDate).toLocaleString()
                  : "Não Finalizado"
              }}
            </td>
            <td>{{ item.finishedComment }}</td>
            <td>
              {{
                item.distance ? `${(item.distance / 1000).toFixed(2)} Km` : ""
              }}
            </td>
            <td>
              <!-- v-if="showIcon(item)" -->
              <v-tooltip left v-if="showIcon(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    @click="$store.dispatch('dialogRelatorioOpen', item)"
                    class="m-1"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-map-search
                  </v-icon>
                </template>
                <span>Informações sobre o trajeto</span>
              </v-tooltip>
              <v-tooltip left v-if="showIcon(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    large
                    @click="$store.dispatch('updateDistanceOc', item.id)"
                    class="m-1"
                    v-on="on"
                    v-bind="attrs"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Atualizar distância</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogReport from "../components/Relatorios/DialogMapReport.vue";
import { generate } from "@/utils/excell.js";
import { orderReport } from "@/utils/pdf.js";
export default {
  name: "RelatorioOrdem",
  components: {
    DialogReport,
  },
  data() {
    return {
      report: {
        dirI: "",
        finishE: "",
        finishS: "",
        loadS: "",
        loadE: "",
        createS: "",
        createE: "",
      },
    };
  },
  methods: {
    loadReport() {
      this.$store.dispatch("getReportBOtoUser", this.report);
      this.$store.dispatch("setBOReportFilter", this.report);
    },
    showIcon(item) {
      if (item.loadedDate && item.uniqueId) {
        return true;
      } else {
        return false;
      }
    },
    exportExcell() {
      let columns = [
        { header: "ID", key: "id", width: 10 },
        { header: "OC", key: "buyOrderId", width: 10 },
        { header: "Fornecedor", key: "provider", width: 32 },
        { header: "Obra", key: "construction", width: 32 },
        { header: "Material", key: "itemsStr", width: 32 },
        { header: "Quantidade", key: "colectedAmount", width: 20 },
        { header: "Enviado por", key: "createUser", width: 32 },
        { header: "Direcionado", key: "createdAt", width: 20 },
        { header: "Descrição", key: "description", width: 32 },
        { header: "Motorista", key: "driverUser", width: 32 },
        { header: "Placa", key: "plate", width: 20 },
        { header: "Carregado", key: "loadedDate", width: 20 },
        { header: "Finalizado por", key: "finishedByUser", width: 32 },
        { header: "Finalizado em", key: "finishedDate", width: 20 },
        { header: "Desc. finalização", key: "finishedComment", width: 32 },
        { header: "Distancia KM", key: "distanceStr", width: 20 },
      ];
      // gera as linhas para o excel
      let rows = this.generateRows(this.getBOReport);
      // nome da planilha
      let nameSheet = "STV Relatório ordens usuário";
      // corpo do excel
      let excelBody = [{ columns, rows, nameSheet }];
      // gera o excel e faz o download
      generate(excelBody).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Relatório Ordens_Mot.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    exportPdf() {
      // gera as linhas para o excel
      let rows = this.generateRows(this.getBOReport);
      // nome da planilha
      let title = "STV Relatório ordens usuário";
      // gera o excel e faz o download
      orderReport(rows, title, null, title.replace(" ", "_"));
    },
    generateRows() {
      let rows = [...this.getBOReport];

      rows.forEach((e) => {
        e.buyOrderId = parseFloat(e.buyOrderId);
        e.provider = this.getProviderNameById(e.order.providerId) || "";
        e.construction =
          this.getConstructionNameById(e.order.constructionId) || "";
        e.itemsStr = e.items.map((e) => e.name).join(" - ");
        e.createUser = this.getUserNameById(e.createUserId) || "";
        e.createdAt = e.createdAt ? new Date(e.createdAt).toLocaleString() : "";
        e.driverUser = this.getUserNameById(e.userId) || "";
        e.loadedDate = e.loadedDate
          ? new Date(e.loadedDate).toLocaleString()
          : "";
        e.finishedByUser = this.getUserNameById(e.finishedByUserId) || "";
        e.finishedDate = e.finishedDate
          ? new Date(e.finishedDate).toLocaleString()
          : "";
        e.distanceStr = e.distance
          ? parseFloat(`${(e.distance / 1000).toFixed(2)}`)
          : "";
      });
      return rows;
    },
    getTodayFinish() {
      let now = new Date();
      this.report.finishS = `${now.toISOString().split("T")[0]} 00:00`;
      this.report.finishE = `${now.toISOString().split("T")[0]} 23:59`;
    },
    getTodayLoad() {
      let now = new Date();
      this.report.loadS = `${now.toISOString().split("T")[0]} 00:00`;
      this.report.loadE = `${now.toISOString().split("T")[0]} 23:59`;
    },
    getTodayCreated() {
      let now = new Date();
      this.report.createS = `${now.toISOString().split("T")[0]} 00:00`;
      this.report.createE = `${now.toISOString().split("T")[0]} 23:59`;
    },
  },
  computed: {
    ...mapGetters([
      "getBOReport",
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOtoUsers",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
      "getVehiclesSelect",
      "getTraceableById",
      "getUserById",
      "getVehicleById",
      "getBOReportFilter",
      "getOrdemSelect",
      "getOrderItemsSelect",
    ]),
  },
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadConstructions");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadBOtoUser");
    // this.$store.dispatch("loadVehicles");
    this.$store.dispatch("loadOrdersItems");
    this.report = { ...this.getBOReportFilter};
  },
};
</script>

<style>
</style>