<template>
  <v-dialog
    v-model="getDialogRelatorioState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Informações sobre o Trajeto</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn dark @click="close"> Fechar </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-card class="mt-1">
          <v-card-title> Resumo da Viajem </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left">Distancia Percorrida</th>
                      <th class="text-left">Velocidade Média</th>
                      <th class="text-left">Velocidade Máxima</th>
                      <th class="text-left">Tempo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ (resume.distance / 1000).toFixed(2) }} Km</td>
                      <td>
                        {{ (resume.averageSpeed * 1.852).toFixed(1) }} Km/h
                      </td>
                      <td>{{ (resume.maxSpeed * 1.852).toFixed(1) }} Km/h</td>
                      <td>{{ resume.difTime }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="success" @click="getResume">Buscar resumo</v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            <p>Rota</p>
            <v-spacer></v-spacer>
            <v-btn class="success" @click="getRoute">Buscar Rota</v-btn>
          </v-card-title>
          <v-card-text>
            <div
              id="mapContainer"
              ref="ctn"
              class="d-flex align-self-stretch"
              :style="`height: 50vh; z-index:3`"
            ></div>
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            <p>Imagens</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                {{ getDialogRelatorio.loadedPic }}
                <p>Foto da Carga</p>
                <v-img
                  lazy-src="@/assets/nopic.jpg"
                  class="d-flex justify-center"
                  :src="getDialogRelatorio.data.loadedPic"
                  @click="dialogImage = getDialogRelatorio.data.finishedPic"
                ></v-img>
              </v-col>
              <v-col cols="6">
                <p>Foto da Descarga</p>
                <v-img
                  lazy-src="@/assets/nopic.jpg"
                  class="d-flex justify-center"
                  :src="getDialogRelatorio.data.finishedPic"
                  @click="dialogImage = getDialogRelatorio.data.finishedPic"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <DialogImage v-model="dialogImage" />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import dateDiff from "@/utils/dateDiff.js";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";
import DialogImage from "@/components/Relatorios/DialogImage.vue";
export default {
  name: "DialogReport",
  props: ["item"],
  components: {
    DialogImage,
  },
  data() {
    return {
      dialog: false,
      resume: {
        distance: 0,
        averageSpeed: 0,
        maxSpeed: 0,
        difTime: "0h 0m",
      },
      map: null,
      route: [],
      markers: [],
      polyline: null,
      getDiff: dateDiff.getDiff,
      dialogImage: "",
    };
  },
  computed: {
    ...mapGetters(["getDialogRelatorio", "getDialogRelatorioState"]),
  },
  methods: {
    close() {
      if (this.getDialogRelatorio.show) {
        this.$store.dispatch("dialogRelatorioClose");
        this.resume = {
          distance: 0,
          averageSpeed: 0,
          maxSpeed: 0,
          difTime: "0h 0m",
          start: "",
          end: "",
        };
      }
    },
    getResume() {
      let payload = {
        BOToUser: this.getDialogRelatorio.data.id,
        start: this.getDialogRelatorio.data.loadedDate,
        end: this.getDialogRelatorio.data.finishedDate,
      };
      if (payload.start == null) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "Data de carregamento não informada"
        );
        return;
      }
      if (payload.end == null) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSnackColor", {
          color: "warning",
          text: "Data de entrega não informada mostrando o que foi percorrido até o momento",
        });
        payload.end = new Date();
      }
      this.$http.post("ocUsuario/resume", payload).then((resp) => {
        this.resume = resp.data;
        this.resume.difTime = this.getDiff(payload.start, payload.end);
      });
    },
    getRoute() {
      let payload = {
        BOToUser: this.getDialogRelatorio.data.id,
        start: this.getDialogRelatorio.data.loadedDate,
        end: this.getDialogRelatorio.data.finishedDate,
      };
      if (payload.start == null) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "Data de carregamento não informada"
        );
        return;
      }
      if (payload.end == null) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSnackColor", {
          color: "warning",
          text: "Data de entrega não informada mostrando o que foi percorrido até o momento",
        });
        payload.end = new Date();
      }
      this.$http.post("ocUsuario/route", payload).then((resp) => {
        this.route = resp.data;
        this.showRoute();
      });
    },
    // getDiff() {
    //   let dtPartida = this.getDialogRelatorio.data.loadedDate;
    //   let dtChegada = this.getDialogRelatorio.data.finishedDate || new Date().toISOString();

    //   let date1 = new Date(
    //     dtPartida.slice(0, 4),
    //     dtPartida.slice(5, 7),
    //     dtPartida.slice(8, 10),
    //     dtPartida.slice(11, 13),
    //     dtPartida.slice(14, 16)
    //   );
    //   let date2 = new Date(
    //     dtChegada.slice(0, 4),
    //     dtChegada.slice(5, 7),
    //     dtChegada.slice(8, 10),
    //     dtChegada.slice(11, 13),
    //     dtChegada.slice(14, 16)
    //   );

    //   let diffMs = date2 - date1;
    //   let diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    //   let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    //   let diff = diffHrs + "h " + diffMins + "m";
    //   this.resume.difTime = diff;
    // },
    initMap() {
      if (this.map != null) {
        return;
      }
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.map = L.map("mapContainer").setView(
        [-14.337566608887226, -54.93164062500001],
        4
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
          this.$store.getters.getMapBoxKey,
        {
          maxZoom: 18,
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.map);
    },
    showRoute() {
      let cords = [];
      if (!this.route.length) {
        return;
      }

      this.markers.forEach((e) => {
        e.remove(this.map);
      });

      if (this.polyline != null) {
        this.polyline.remove(this.map);
      }

      this.markers = [];

      this.route.forEach((e) => {
        let b = L.marker([e.latitude, e.longitude])
          .bindTooltip(
            `<table>                
                <tr>
                    <td>Endereço:</td>
                    <td style="text-align: center;">
                      ${e.address}
                    </td>
                </tr>
                <tr>
                    <td>Velocidade:</td>
                    <td style="text-align: center;">
                      ${(e.speed * 1.825).toFixed(1)} Km/h
                    </td>
                </tr>
                <tr>
                    <td>Última Posição:</td>
                    <td style="text-align: center;">
                      ${new Date(e.fixTime).toLocaleString()}
                    </td>
                </tr>
            </table>
            `
          )
          .addTo(this.map);

        cords.push([e.latitude, e.longitude]);

        this.markers.push(b);
      });

      if (cords.length) {
        this.polyline = L.polyline(cords).addTo(this.map);
        this.map.fitBounds(cords);
      }
    },
  },
  watch: {
    getDialogRelatorioState() {
      if (this.getDialogRelatorioState) {
        setTimeout(() => {
          this.initMap();
        }, 500);
      } else {
        this.markers.forEach((e) => {
          e.remove(this.map);
        });
        if (this.polyline != null) {
          this.polyline.remove(this.map);
        }
      }
    },
  },
};
</script>

<style>
</style>