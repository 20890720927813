<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark>
        <v-btn icon dark @click="$emit('input', '')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imagem</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn dark @click="$emit('input', '')"> Fechar </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col>

        <!-- div com os botões -->
        <div class="btnAngle">
          <v-btn text icon large color="primary" @click="imageAngle -= 90">
            <v-icon>mdi-rotate-left</v-icon>
          </v-btn>
          <v-btn text icon large color="primary" @click="imageAngle += 90">
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>
        </div>
        <v-img
          :src="value"
          lazy-src="@/assets/nopic.jpg"
          :style="'transform: rotate(' + imageAngle + 'deg);'"
        >
        </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogImage",
  props: ["value"],
  data() {
    return {
      imageAngle: 0,
      dialog: false,
    };
  },
  computed: {
  },
  methods: {},
  watch: {
    value() {
      if(typeof this.value === 'string' && this.value.length > 0) {
        this.dialog = true;
        this.imageAngle = 0;
      } else {
        this.dialog = false;
      }
    }
  },
};
</script>

<style>
.btnAngle {
  position: relative;
  left: 45%;
  z-index: 1;
  height: 0;
  max-width: 50%;
}
</style>